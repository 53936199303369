import React, { useState } from "react";
import axios from "axios";
import * as qs from 'query-string';
import { FormattedMessage, injectIntl } from "gatsby-plugin-intl";
import { Link, navigateTo } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

// Styles
import styles from "../sass/pages/contact.module.scss";

class HireUsPage extends React.Component {

    type1: any;
    type2: any;
    type3: any;
    type4: any;
    title: string;
    domRef: any;
    constructor(props: any) {
        super(props);
        this.title = this.props.intl.formatMessage({ id: 'contact' });
        this.type1 = this.props.intl.formatMessage({ id: 'contact_type_1' });
        this.type2 = this.props.intl.formatMessage({ id: 'contact_type_2' });
        this.type3 = this.props.intl.formatMessage({ id: 'contact_type_3' });
        this.type4 = this.props.intl.formatMessage({ id: 'contact_type_4' });

        this.domRef = React.createRef()
        this.state = { feedbackMsg: null }
    }

    handleAttachment = e => {
        this.setState({ [e.target.name]: e.target.files[0] });
    };

    encodeData = (data: any, formFields: any) => {
        const formData = new FormData();

        Object.keys(data).forEach((key) => formData.append(key, data[key]));
        Object.keys(this.refs).map((key) => formData.append(key, this.refs[key].value));
        return formData;
    };

    handleSubmit(event: Event) {
        event.preventDefault();
        const form = event.target;

        // //add form field to form data
        const formData = {};
        Object.keys(this.refs).map(key => (formData[key] = this.refs[key].value));
        const axiosOptions = {
            url: this.props.location.pathname,
            method: "post",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            data: this.encodeData({
                'file': this.state.file
            }, formData)
        };

        //post no netlify using axios
        axios(axiosOptions)
            .then(response => {
                this.setState({
                    feedbackMsg: "Form submitted successfully!",
                });
                this.domRef.current.reset();
                navigateTo(form.getAttribute("action"));
            })
            .catch(err =>
                this.setState({
                    feedbackMsg: "Form could not be submitted.",
                })
            );
    }

    render() {
        return (
            <Layout color="">
                <SEO title={this.title} />
                <article className="container-small">
                    <div className="space"></div>
                    <h2><FormattedMessage id="contact_title" values={{ breakLine: <br /> }} /> </h2>
                    <div className="space"></div>
                    <form ref={this.domRef} name="hire" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/message" onSubmit={event => this.handleSubmit(event)}>
                        <input type="hidden" ref="form-name" name="form-name" value="hire" />
                        <div hidden aria-hidden="true">
                            <label>
                                <FormattedMessage id="human" values={{ breakLine: <br /> }} />
                                <input name="bot-field" />
                            </label>
                        </div>
                        <div className="grid-cards-2-1">
                            <div>
                                <label className={"p-small"}><FormattedMessage id="contact_name" /></label>
                                <input type="text" id="contactName" name="contactName" ref="contactName" className={styles.inputContact} />
                            </div>
                            <div>
                                <label className={"p-small"}><FormattedMessage id="contact_email" /></label>
                                <input type="email" id="email" name="email" ref="email" className={styles.inputContact} />
                            </div>
                            <div>
                                <label className={"p-small"}><FormattedMessage id="contact_companyname" /></label>
                                <input type="text" id="companyName" name="companyName" ref="companyName" className={styles.inputContact} />
                            </div>
                            <div>
                                <label className={"p-small" }><FormattedMessage id="contact_role" /></label>
                                <input type="text" id="role" name="role" ref="role" className={styles.inputContact} />
                            </div>
                            <div>
                                <label className={"p-small"}><FormattedMessage id="contact_type" /></label>
                                <select name="contactType" id="contactType" ref="contactType" className={"p-small"}>
                                    <option value={this.type1}>{this.type1}</option>
                                    <option value={this.type2}>{this.type2}</option>
                                    <option value={this.type3}>{this.type3}</option>
                                    <option value={this.type4}>{this.type4}</option>
                                </select>
                            </div>
                            <div>
                                <label className={"p-small"}><FormattedMessage id="attach_files_title" /></label>
                                <input type="file" id="file" name="file" onChange={this.handleAttachment} className={styles.dragAndDrop + " " + "p-small"} />
                            </div>
                        </div>
                        <label className={"p-small"} htmlFor="text"><FormattedMessage id="text" /></label>
                        <textarea name="tell_us_something" id="tell_us_something" ref="tell_us_something"></textarea>
                        <div>
                            <button className="btn btn-main" type="submit"><FormattedMessage id="contact_send_button" /></button>
                        </div>
                    </form>
                    <div className="space"></div>
                    <div className="space"></div>
                </article>
            </Layout>
        );
    }
}
export default injectIntl(HireUsPage);

